<template>
  <Animated name="pulse" appear duration="1200">
    <div class="">
      <sequential-entrance fromTop>
        <SellerHeader v-if="this.ticket?.reference == 'Venta Vip'" to="/seller/links/vip" headerText="Link generado" />
        <SellerHeader v-else :to="getLinkRoute()" headerText="Link generado" />
      </sequential-entrance>

      <div class=" px-5 pb-5 pt-5" v-if="ticket">
        {{ messageToShare }}

        <v-row class="mt-5 pt-5">
          <v-col cols="12" class="pt-0 pb-0" @click="goToLink()">
            <v-text-field required :value="ticketLink" color="#00B8D9" solo flat disabled background-color="#F4F5F7"
              class="app-input pt-0"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0">
          <v-col cols="3" class="pt-0 pb-0">
            <v-btn class="app-btn__gray fluid-btn px-10" rounded depressed large color="primary" @click="copyToClipBoard">
              <v-icon color="#fff" center> mdi-content-copy </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3" class="pt-0 pb-0">
            <v-btn class="fluid-btn px-10" rounded depressed color="#54dcd0" large @click="showShareTicketModal">
              <v-icon color="#fff" center> mdi-email-outline </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3" class="pt-0 pb-0">
            <v-btn class="fluid-btn px-10" rounded depressed color="#199c4a" large @click="whatsappShare">
              <v-icon color="#fff" center> mdi-whatsapp </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3" class="pt-0 pb-0">
            <v-btn class="fluid-btn px-10" rounded depressed v-bind:color="ticketHasPetition ? 'warning' : '#CF1B30'"
              large v-on="canDeleteTicket
                ? { click: () => showDeleteConfirmationModal() }
                : { click: () => showDeletePetitionModal() }
                ">
              <v-icon color="#fff" center> mdi-delete </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <v-btn class="app-btn__secondary fluid-btn px-10" rounded depressed color="#1E87FD" large @click="editTicket">
              Editar
              <v-icon color="#fff" center> mdi-pencil-outline </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- Share ticket via mail -->
        <b-modal ref="share-modal" hide-footer title="Enviar link por mail">
          <div class="d-block text-center">
            <h3 class="font-weight-bold">{{ this.ticket.reference }}</h3>
          </div>
          <div>
            <div class="pt-1">
              <b-form @submit="onSubmitShareTicket">
                <label>Destinatario/s:</label>
                <b-form-group v-for="index in this.ticket.quota" :key="index">
                  <b-form-input id="to" type="email" :placeholder="'Destinatario ' + index"></b-form-input>
                </b-form-group>

                <div class="py-3">
                  <label for="subject">Asunto:</label>
                  <b-form-input id="subject" :value="this.ticket.event.name + ' - Link registro de asistentes'
                    " type="text" disabled></b-form-input>
                </div>

                <div class="pb-3">
                  <label for="link">Link:</label>
                  <b-form-input id="link" :value="this.ticketLink" type="text" disabled></b-form-input>
                </div>

                <label for="message">Mensaje:</label>
                <b-form-textarea id="message" :value="this.messageToShare" type="text" rows="6"
                  disabled></b-form-textarea>

                <div class="text-center pt-4">
                  <b-button type="submit" variant="primary">Enviar</b-button>
                </div>
              </b-form>
            </div>
          </div>
        </b-modal>

        <!-- Create delete petition Modal -->
        <b-modal ref="delete-modal" hide-footer title="Crear petición para eliminar link">
          <div class="d-block text-center">
            <h3 class="font-weight-bold">{{ this.ticket.reference }}</h3>
          </div>
          <!-- Si no hay peticiones, que permita crearla -->
          <div v-if="!ticketHasPetition">
            <p class="pt-3">
              No tienes permiso para eliminar el link; crea una petición para
              que un administrador lo haga
            </p>
            <div class="pt-3">
              <b-form @submit="onSubmitDeletePetition">
                <b-form-group id="input-group-1" label-for="input-1"
                  description="Ingresa un motivo por el cual quieres eliminar el Link!">
                  <b-form-input id="input-1" v-model="form.reason" type="text" placeholder="Motivo"
                    required></b-form-input>
                </b-form-group>

                <div class="text-center pt-2">
                  <b-button type="submit" variant="primary">Enviar</b-button>
                </div>
              </b-form>
            </div>
          </div>

          <!-- Si existe una petición, que muestre sus datos -->
          <div v-if="ticketHasPetition" class="pb-3">
            <p class="font-weight-bold">
              Ya existe una petición para este ticket!
            </p>

            <p>Creada por: {{ ticketHasPetition.seller_issuer.fullname }}</p>
            <p>Creada el: {{ date(ticketHasPetition.created_at) }}</p>
            <p>
              Tipo:
              <strong class="text-uppercase">{{
                ticketHasPetition.type
              }}</strong>
            </p>
            <p v-if="ticketHasPetition.updated_at">
              Actualizada el: {{ date(ticketHasPetition.updated_at) }}
            </p>
            <p>
              Estado:
              <strong class="text-uppercase">{{
                ticketHasPetition.status
              }}</strong>
            </p>
          </div>
        </b-modal>

        <!-- Create edit petition Modal -->
        <b-modal ref="edit-modal" hide-footer title="Crear petición para editar link">
          <!-- Si no hay peticiones, que permita crearla -->
          <div v-if="!ticketHasPetition">
            <p class="pt-3">
              No tienes permiso para editar el link; crea una petición para que
              un administrador lo haga
            </p>
            <div class="pt-3">
              <b-form @submit="onSubmitEditPetition">
                <b-form-group id="input-group-1" label-for="input-1"
                  description="Ingresa un motivo por el cual quieres editar el Link!">
                  <b-form-input id="input-1" v-model="editForm.reason" type="text" placeholder="Motivo"
                    required></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-1" label-for="input-1" description="Ingresa la nueva cantidad de tickets">
                  <b-form-input id="input-1" v-model="editForm.quota" type="number"
                    placeholder="Cantidad de tickets (máximo 10)" required></b-form-input>
                </b-form-group>

                <div class="text-center pt-2">
                  <b-button type="submit" variant="primary">Enviar</b-button>
                </div>
              </b-form>
            </div>
          </div>

          <!-- Si existe una petición, que muestre sus datos -->
          <div v-if="ticketHasPetition" class="pb-3">
            <p class="font-weight-bold">
              Ya existe una petición para este ticket!
            </p>

            <p>Creada por: {{ ticketHasPetition.seller_issuer.fullname }}</p>
            <p>Creada el: {{ date(ticketHasPetition.created_at) }}</p>
            <p>
              Tipo:
              <strong class="text-uppercase">{{
                ticketHasPetition.type
              }}</strong>
            </p>
            <p v-if="ticketHasPetition.updated_at">
              Actualizada el: {{ date(ticketHasPetition.updated_at) }}
            </p>
            <p>
              Estado:
              <strong class="text-uppercase">{{
                ticketHasPetition.status
              }}</strong>
            </p>
          </div>
        </b-modal>

        <!-- Delete confirmation Modal -->
        <b-modal ref="delete-confirmation-modal" centered title="Confirmar borrado de ticket" header-bg-variant="dark"
          header-text-variant="light" hide-footer>
          <div class="text-center">
            <p class="font-weight-bold">
              ¿Estás seguro que deseas eliminar el ticket?
            </p>
            <b-button class="mx-3" variant="danger" @click="deleteTicket()">Eliminar</b-button>
          </div>
        </b-modal>

        <h4 class="mt-10">Asistentes</h4>

        <div class="assistants mt-5" v-if="getAssistants.length">
          <div class="assistant" v-for="(assistant, index) in getAssistants" :key="index">
            <v-row class="align-center">
              <v-col cols="7">
                <p class="
													mb-0
													font-weight-bold
													text-color-soft-black
												">
                  {{ assistant.fullname }} <span style="font-size: .9rem; font-weight: 400;"
                    v-if="assistant.principal_contact">(PRINCIPAL)</span>
                </p>
                <p style="font-size: 10px !important;" class="text-color-soft-black">
                  DNI: <strong>{{ assistant.dni }}</strong>
                </p>
              </v-col>

              <!-- Botón de Ver QR y Eliminar Asistente en caso de que no esté verificado -->
              <v-col cols="4" class="d-flex" v-if="!assistant.verified">
                <v-btn dark small color="primary" class="mx-2" @click="onShowQrAssistant(assistant, index)"
                  v-if="assistant.fullname != 'anonimo'">
                  <v-icon>
                    mdi-qrcode
                  </v-icon>
                </v-btn>
                <v-btn dark small color="error" class="mx-2" @click="onDeleteTicketAssistantId(assistant.id, index)"
                  v-if="assistant.fullname != 'anonimo'">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>

              <!-- Mostrar fecha de verificación del Asistente en caso de que esté verificado -->
              <v-col cols="4" class="d-flex justify-content-between" v-if="assistant.verified">
                <v-col cols="2" class="p-0 d-flex">
                  <v-icon color="green">
                    mdi-check-decagram-outline
                  </v-icon>
                </v-col>

                <v-col cols="10">
                  <p class="mb-0">
                    <strong>
                      Verificado
                    </strong>
                    {{ date(assistant.verified_date) }}
                  </p>
                </v-col>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="assistants empty" v-else>
          <h4>No hay información de asistentes</h4>
        </div>
      </div>

      <v-container v-if="!ticket">
        <div class="py-5">
          <b-skeleton height="40px"></b-skeleton>
        </div>

        <v-row justify="center">
          <v-col cols="3">
            <b-skeleton class="m-auto" type="avatar"></b-skeleton>
          </v-col>
          <v-col cols="3">
            <b-skeleton class="m-auto" type="avatar"></b-skeleton>
          </v-col>
          <v-col cols="3">
            <b-skeleton class="m-auto" type="avatar"></b-skeleton>
          </v-col>
          <v-col cols="3">
            <b-skeleton class="m-auto" type="avatar"></b-skeleton>
          </v-col>
        </v-row>

        <div class="py-5">
          <b-skeleton width="40%" height="30px"></b-skeleton>
        </div>
        <b-skeleton height="40px"></b-skeleton>
        <b-skeleton height="40px"></b-skeleton>
        <b-skeleton height="40px"></b-skeleton>
      </v-container>
    </div>
  </Animated>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import {
  getTicketInfo,
  deleteTicketAssistantId,
  deleteTicketId,
  postPetition,
  shareTicketByMail,
} from '@/services/seller.service'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
//import ViewQR from "@/components/QR/ViewQR.vue";
import { getMyData } from '@/services/user.service'
import { ErrorMessages } from '@/helpers/constants'
import { idoDateToDateAndHours } from '@/helpers/date'
import { encryptQueryParams } from 'query-string-hash'
import { eventDate, eventDateTime } from '@/helpers/date'
import { getNameRoute } from '@/utils/router/router.utils'

export default {
  data() {
    return {
      ticket: {},
      showAssistants: false,
      showQR: false,
      selectedAssistant: null,
      form: {
        reason: '',
      },
      editForm: {
        reason: '',
        quota: '',
      },
      user: null,
      ticketHasPetition: null,
      nameRoute: ''
    }
  },
  computed: {
    styleTicket() {
      return this.ticket?.event?.config
    },
    ticketFontColor() {
      return this.styleTicket?.font_color
    },
    ticketBgColor() {
      return this.styleTicket?.background_color
    },
    styleQR() {
      return this.styleTicket?.qr
    },
    ticketLink() {
      return (
        this.ticket &&
        `${process.env.VUE_APP_DOMAIN_ECOMMERCE}/ticket-verification/${this.ticket?.uuid}`
      )
    },
    getAssistants() {
      return this.ticket?.assistants ?? []
    },
    canDeleteTicket() {
      // Condición para eliminar ticket: que no tenga asistentes y que el evento tenga permiso de edición
      return !this.getAssistants.length && this.ticket?.event?.edit_tickets == 1
        ? true
        : false
    },
    messageQuota() {
      return (
        'Link habilitado para registrar ' +
        this.ticket.quota +
        ' asistente/s. ' +
        this.ticket.product.share_message
      )
    },
    messageToShare() {
      if (this.ticket.product.is_vip == 1) {
        let message = `${this.ticket.assistants[0].fullname
          }, te comparto el link con los QR de todos los invitados al evento ${this.ticket.event.name
          }.
        Tus invitados tendrán acceso a ${this.ticket.product.name
          } que tendra lugar el ${eventDate(
            this.ticket.product.initial_date
          )} a las ${eventDateTime(this.ticket.product.initial_date)}.
        Accediendo a este link cada uno de los invitados debera descargar su QR y presentarlo en la puerta el dia del evento.
        `

        if (this.ticket.product.min_age > 0) {
          message += `Te recuerdo que todos los invitados deben ser mayores de ${this.ticket.product.min_age} y deberan presentar DNI para ingresar.`
        }

        return message
      } else {
        return this.ticket.product.min_age && this.ticket.product.min_age != 0
          ? this.messageQuota.concat(
            '. ',
            'Producto habilitado para mayores de ' +
            this.ticket.product.min_age +
            ' años'
          )
          : this.ticket.product.share_message
      }
    },
  },
  methods: {
    showDeletePetitionModal() {
      this.$refs['delete-modal'].show()
    },
    hideModal() {
      this.$refs['delete-modal'].hide()
    },
    showEditModal() {
      this.$refs['edit-modal'].show()
    },
    hideEditModal() {
      this.$refs['edit-modal'].hide()
    },
    showDeleteConfirmationModal() {
      this.$refs['delete-confirmation-modal'].show()
    },
    showShareTicketModal() {
      this.$refs['share-modal'].show()
    },
    hideShareTicketModal() {
      this.$refs['share-modal'].hide()
    },
    async onSubmitDeletePetition(event) {
      event.preventDefault()
      try {
        const eventId = localStorage.getItem('selectedEvent')

        const body = {
          event_id: eventId,
          reason: this.form.reason,
          ticket_id: this.ticket.id,
          user_id: this.user.id,
          type: 'delete',
        }
        const response = await postPetition(body)

        if (response.status == '200') {
          this.getTicketInfo()
          this.hideModal()
          Vue.$toast.success(`Petición enviada correctamente`)
        } else {
          Vue.$toast.error(ErrorMessages.COMMON)
        }
      } catch (e) {
        console.error(e)
      }
    },
    async onSubmitEditPetition(event) {
      event.preventDefault()
      try {
        const eventId = localStorage.getItem('selectedEvent')

        const body = {
          event_id: eventId,
          reason: this.editForm.reason,
          ticket_id: this.ticket.id,
          user_id: this.user.id,
          type: 'edit',
          edit_quota: this.editForm.quota,
        }
        const response = await postPetition(body)

        if (response.status == '200') {
          this.getTicketInfo()
          this.hideEditModal()
          Vue.$toast.success(`Petición enviada correctamente`)
        } else {
          Vue.$toast.error(ErrorMessages.COMMON)
        }
      } catch (e) {
        console.error(e)
      }
    },
    async onSubmitShareTicket(event) {
      event.preventDefault()

      try {
        const receiverArray = []

        if (event.target.elements.to.length) {
          event.target.elements.to.forEach(element => {
            if (element.value) {
              const receiver = {
                value: element.value,
              }
              receiverArray.push(receiver)
            }
          })
        } else {
          if (event.target.to.value) {
            const receiver = {
              value: event.target.to.value,
            }
            receiverArray.push(receiver)
          }
        }

        const body = {
          message: this.messageToShare,
          link: this.ticketLink,
          event_name: this.ticket.event.name,
          receiver: JSON.stringify(receiverArray),
        }

        const response = await shareTicketByMail(body)

        if (response.status == '200') {
          this.hideShareTicketModal()
          Vue.$toast.success(`Correo enviado correctamente`)
        } else {
          Vue.$toast.error(ErrorMessages.COMMON)
        }
      } catch (e) {
        console.error(e)
        Vue.$toast.error(ErrorMessages.COMMON)
      }
    },
    editTicket() {
      if (this.ticket.event.edit_tickets == 1) {
        //this.goToProductDetail();
        /**
         * Arreglo provisorio ya que no funciona el EP de edicion. Por ende mejor crear una peticion que esa si funciona.
         */
        this.showEditModal()
      } else {
        this.showEditModal()
      }
    },
    date(isoDate) {
      return idoDateToDateAndHours(isoDate)
    },
    copyToClipBoard() {
      navigator.clipboard.writeText(this.ticketLink).then(
        function () {
          console.log('Async: Copying to clipboard was successful!')
        },
        function (err) {
          console.error('Async: Could not copy text: ', err)
        }
      )
    },
    toggleShowAssistants() {
      this.showAssistants = !this.showAssistants
    },
    whatsappShare() {
      window.open(
        `https://wa.me?text=${encodeURIComponent(this.ticketLink)} ${this.messageToShare
        }`,
        '_blank'
      )
    },
    goToProductDetail() {
      const {
        id,
        reference,
        payment_status,
        payment_method,
        quota,
        product,
        petitions,
        event,
      } = this.ticket

      this.$router.push({
        name: 'Generar Link',
        params: { productId: product.uuid },
        query: {
          ticketId: id.toString(),
          reference: reference,
          productStatus: payment_status.toString(),
          productPayment: payment_method,
          quota: quota.toString(),
          edit: 'true',
        },
      })
    },
    async onShowQrAssistant(assistant) {
      const url = `${process.env.VUE_APP_DOMAIN_ECOMMERCE}/ticket/${this.ticket?.uuid}/assistant/${assistant.uuid}`
      window.open(url, '_blank')
    },
    async getTicketInfo() {
      try {
        const data = await getTicketInfo(this.$route.params.linkId)
        this.ticket = data

        // Buscar si el ticket tiene una peticion activa (pendiente); para no permitir crear otra peticion encima de ella
        const response = JSON.parse(JSON.stringify(data.petitions))
        this.ticketHasPetition = response.find(
          petition => petition.status == 'pending'
        )
      } catch (error) {
        return false
      }
    },
    async onDeleteTicketAssistantId(assistantId, index) {
      try {
        const { status } = await deleteTicketAssistantId(assistantId)
        if (status) {
          this.ticket.assistants.splice(index, 1)
          Vue.$toast.success(`Asistente borrado correctamente!`)
        }
      } catch (error) {
        if (error.response.status == 422) {
          Vue.$toast.warning(error.response.data.message)
        }
        return false
      }
    },
    async deleteTicket() {
      const { id: ticketId } = this.ticket
      try {
        const { status = false } = await deleteTicketId(ticketId)
        if (status) {
          this.$router.push({
            name: 'Links',
          })
        }
      } catch (error) {
        if (error.response.status == 422) {
          Vue.$toast.warning(error.response.data.message)
          //this.showDeletePetitionModal();
        }
        return false
      }
    },
    async getMyData() {
      try {
        const { data } = await getMyData()
        this.user = data
      } catch (e) {
        console.error(e)
      }
    },
    goToLink() {
      window.open(this.ticketLink, '_blank')
    },

    getLinkRoute() {
      console.log(this.nameRoute);
      return this.nameRoute === 'Link-how-admin' ? '/seller/all-links/' : '/seller/links/';
    },


  },
  mounted() {
    this.getTicketInfo()
    this.getMyData()

    this.nameRoute = getNameRoute(this.$route);

  },
  components: {
    SellerHeader,
  },
}
</script>

<style lang="scss" scope>
.app-border-login {
  .qr-spacer {
    margin-top: 40% !important;
    background: blue;
  }
}

.fluid-btn {
  max-width: 100% !important;
  width: 100% !important;
}

.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}

.theme--dark.v-btn--active:hover::before,
.theme--dark.v-btn--active::before {
  opacity: 0;
}

.assistants {
  .assistant {
    background: #f7f7f7;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin: 1rem 0;
  }

  &.empty {
    margin-top: 2rem;
    color: #a1a1a1;
    justify-content: center;
    display: flex;
  }
}

.wrapper__qr-ticket {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.wrapper__ticket_success {
  background: #019af9;
  color: #fff;
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

button {
  margin: auto;
  display: block;
}

small {
  font-size: 12px;
}

.v-dialog {
  border-radius: 0px !important;
}
</style>
